var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$route.path.includes('print'))?_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('div',[_c('span',{staticClass:"font-weight-bold mr-1"},[_vm._v(" Initiative : ")]),_c('DropdownSelection',{staticClass:"w-25",attrs:{"text":_vm.selectedTransformationInitiative.text,"items":_vm.transformationInitiativeOptions},on:{"selected":_vm.selectInitiative}})],1),_c('div',[_c('span',{staticClass:"font-weight-bold mr-1"},[_vm._v(" Type objet : ")]),_c('DropdownSelection',{staticClass:"w-25",attrs:{"text":_vm.selectedTransformationApplication.text,"items":_vm.transformationApplicationOptions},on:{"selected":(item) => (_vm.selectedTransformationApplication = item)}})],1),(
        _vm.TRANSFORMATION_PLANS &&
        _vm.TRANSFORMATION_PLANS.initiatives &&
        _vm.transformationPlanItems.length
      )?_c('div',{staticClass:"mdl_btn bg-primary",staticStyle:{"font-size":"20px","cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.exportToPDF.apply(null, arguments)}}},[_c('i',{staticClass:"mdi mdi-printer",staticStyle:{"color":"white"}})]):_c('div')]):_vm._e(),(
      _vm.TRANSFORMATION_PLANS &&
      _vm.TRANSFORMATION_PLANS.initiatives &&
      _vm.TRANSFORMATION_PLANS.initiatives.length
    )?_c('div',{ref:"plan"},[(
        !_vm.$route.path.includes('print') &&
        _vm.TRANSFORMATION_PLANS &&
        _vm.TRANSFORMATION_PLANS.initiatives &&
        _vm.TRANSFORMATION_PLANS.initiatives.length &&
        _vm.selectedTransformationApplication.value === 'application'
      )?_c('div',{staticClass:"w-100 d-flex flex-column border p-2 mt-4 rounded"},[_vm._l((_vm.groupBy(
          _vm.transformationPlanItems,
          'application.capacite.id'
        )),function(transformation,index){return _c('div',{key:index},[_c('p',{staticClass:"text-secondary pb-0"},[_vm._v(" "+_vm._s(transformation[0].application && transformation[0].application.capacite ? transformation[0].application.capacite.name : "N/A")+" ")]),_c('div',{staticClass:"row rounded bg-light shadow-sm border col-12 mx-auto mb-3 p-0"},_vm._l((transformation),function(tran,i){return _c('div',{key:'tran' + i,staticClass:"col-12 col-md-2"},[_c('div',{staticClass:"py-2"},[_c('div',[_c('b-link',{class:'rounded px-3  py-2 text-truncate  d-inline-block text-white text-decoration-none ' +
                    _vm.setBgColor(tran.type),staticStyle:{"cursor":"pointer"},attrs:{"to":{
                    name: 'show-application',
                    params: {
                      id: tran.application.id,
                      slug: tran.application.slug || 'abcde',
                    },
                  }},on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_vm._v(" "+_vm._s(tran.application.name)+" ")]),(!tran.application)?_c('div',{staticClass:"text-gray text-center w-100"},[_c('i',{staticClass:"mdi mdi-information-outline my-auto"}),_vm._v(" Section vide ")]):_vm._e()],1)])])}),0),(!transformation.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e()])}),(
          !Object.keys(
            _vm.groupBy(_vm.transformationPlanItems, 'application.capacite.id')
          ).length
        )?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e()],2):_vm._e(),(
        !_vm.$route.path.includes('print') &&
        _vm.TRANSFORMATION_PLANS &&
        _vm.TRANSFORMATION_PLANS.initiatives &&
        _vm.TRANSFORMATION_PLANS.initiatives.length &&
        _vm.selectedTransformationApplication.value === 'composants'
      )?_c('div',{staticClass:"w-100 d-flex flex-column border p-2 mt-4 rounded"},[_vm._l((_vm.groupBy(
          _vm.transformationPlanItems,
          'composant.capacites.id'
        )),function(transformation,index){return _c('div',{key:index,staticClass:"w-100 mb-3"},[_c('p',{staticClass:"text-secondary pb-0"},[_vm._v(" "+_vm._s(transformation[0].composant && transformation[0].composant.capacite ? transformation[0].composant.capacite.name : "N/A")+" ")]),_c('div',{staticClass:"row rounded bg-light shadow-sm border col-12 mx-auto mb-3 p-0"},_vm._l((transformation),function(tran,i){return _c('div',{key:'tran' + i,staticClass:"col-12 col-md-2"},[_c('div',{staticClass:"py-2"},[_c('div',[_c('b-link',{class:'rounded px-3  py-2 text-truncate   d-inline-block text-white text-decoration-none ' +
                    _vm.setBgColor(tran.type),staticStyle:{"cursor":"pointer"},attrs:{"to":{
                    name: 'show-composant',
                    params: {
                      id: tran.composant.id,
                      slug: tran.composant.slug || 'abcde',
                    },
                  }},on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_vm._v(" "+_vm._s(tran.composant.name)+" ")]),(!tran.composant)?_c('div',{staticClass:"text-gray text-center w-100"},[_c('i',{staticClass:"mdi mdi-information-outline my-auto"}),_vm._v(" Section vide ")]):_vm._e()],1)])])}),0)])}),(
          !Object.keys(
            _vm.groupBy(_vm.transformationPlanItems, 'composant.capacites.id')
          ).length
        )?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e()],2):_vm._e(),(
        _vm.$route.path.includes('print') &&
        _vm.TRANSFORMATION_PLANS &&
        _vm.TRANSFORMATION_PLANS.initiatives &&
        _vm.TRANSFORMATION_PLANS.initiatives.length
      )?_c('div',{staticClass:"w-100 d-flex flex-column border p-2 mt-4 rounded"},_vm._l((_vm.groupBy(
          _vm.transformationPlanApplications,
          'application.capacite.id'
        )),function(transformation,index){return _c('div',{key:index},[_c('p',{staticClass:"text-secondary pb-0"},[_vm._v(" "+_vm._s(transformation[0].application && transformation[0].application.capacite ? transformation[0].application.capacite.name : "N/A")+" ")]),_c('div',{staticClass:"row rounded bg-light shadow-sm border col-12 mx-auto mb-3 p-0"},_vm._l((transformation),function(tran,i){return _c('div',{key:'tran' + i,staticClass:"col-12 col-md-2"},[_c('div',{staticClass:"py-2"},[_c('div',[_c('span',{class:'rounded px-3  py-2 text-truncate   d-inline-block text-white text-decoration-none ' +
                    _vm.setBgColor(tran.type),staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_vm._v(" "+_vm._s(tran.application.name)+" ")]),(!tran.application)?_c('div',{staticClass:"text-gray text-center w-100"},[_c('i',{staticClass:"mdi mdi-information-outline my-auto"}),_vm._v(" Section vide ")]):_vm._e()])])])}),0),(!transformation.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e()])}),0):_vm._e(),(
        _vm.$route.path.includes('print') &&
        _vm.TRANSFORMATION_PLANS &&
        _vm.TRANSFORMATION_PLANS.initiatives &&
        _vm.TRANSFORMATION_PLANS.initiatives.length
      )?_c('div',{staticClass:"w-100 d-flex flex-column border p-2 mt-4 rounded"},[_vm._l((_vm.groupBy(
          _vm.transformationPlanComposants,
          'composant.capacites.id'
        )),function(transformation,index){return _c('div',{key:index,staticClass:"w-100 mb-3"},[_c('p',{staticClass:"text-secondary pb-0"},[_vm._v(" "+_vm._s(transformation[0].composant && transformation[0].composant.capacite ? transformation[0].composant.capacite.name : "N/A")+" ")]),_c('div',{staticClass:"row rounded bg-light shadow-sm border col-12 mx-auto mb-3 p-0"},_vm._l((transformation),function(tran,i){return _c('div',{key:'tran' + i,staticClass:"col-12 col-md-2"},[_c('div',{staticClass:"py-2"},[_c('div',[_c('span',{class:'rounded px-3  py-2 text-truncate   d-inline-block text-white text-decoration-none ' +
                    _vm.setBgColor(tran.type),staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(tran.composant.name)+" ")]),(!tran.composant)?_c('div',{staticClass:"text-gray text-center w-100"},[_c('i',{staticClass:"mdi mdi-information-outline my-auto"}),_vm._v(" Section vide ")]):_vm._e()])])])}),0)])}),(
          !Object.keys(
            _vm.groupBy(_vm.transformationPlanItems, 'composant.capacites.id')
          ).length
        )?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e()],2):_vm._e(),(
        _vm.$route.path.includes('print') &&
        _vm.TRANSFORMATION_PLANS &&
        _vm.TRANSFORMATION_PLANS.initiatives &&
        _vm.TRANSFORMATION_PLANS.initiatives.length
      )?_c('div',{staticClass:"w-100 d-flex flex-column border p-2 mt-4 rounded"},[_vm._l((_vm.groupBy(
          _vm.transformationPlanApplications,
          'application.capacite.id'
        )),function(transformation,index){return _c('div',{key:index},[_c('p',{staticClass:"text-secondary pb-0"},[_vm._v(" "+_vm._s(transformation[0].application && transformation[0].application.capacite ? transformation[0].application.capacite.name : "N/A")+" ")]),_c('div',{staticClass:"row rounded bg-light shadow-sm border col-12 mx-auto mb-3 p-0"},_vm._l((transformation),function(tran,i){return _c('div',{key:'tran' + i,staticClass:"col-12 col-md-2"},[_c('div',{staticClass:"py-2"},[_c('div',[_c('span',{class:'rounded px-3  py-2 text-truncate   d-inline-block text-white text-decoration-none ' +
                    _vm.setBgColor(tran.type),staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(tran.application.name)+" ")]),(!tran.application)?_c('div',{staticClass:"text-gray text-center w-100"},[_c('i',{staticClass:"mdi mdi-information-outline my-auto"}),_vm._v(" Section vide ")]):_vm._e()])])])}),0),(!transformation.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e()])}),(
          !Object.keys(
            _vm.groupBy(_vm.transformationPlanItems, 'application.capacite.id')
          ).length
        )?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e()],2):(
        !_vm.TRANSFORMATION_PLANS &&
        !_vm.TRANSFORMATION_PLANS.initiatives &&
        !_vm.TRANSFORMATION_PLANS.initiatives.length
      )?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e()]):_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }