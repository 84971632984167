<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <b-button :disabled="printing" variant="secondary" @click="exportToPDF">
          <b-spinner
            v-if="printing"
            small
            class="text-white mr-1"
            label="Loading..."
          ></b-spinner
          ><i class="mdi mdi-printer mr-1"></i> Télécharger
        </b-button>
      </div>
    </div>

    <section ref="document">
      <!-- First Page -->
      <div class="page page-1 container-fluid rounded py-4 px-5 mt-4 bg-white">
        <div class="row mt-3">
          <div class="col-12">
            <p class="text-secondary my-auto">
              <i :class="$route.meta.iconClass"></i>
              <span style="font-size: 18px" class="font-weight-light">
                Fiche FdR :
              </span>
              <span style="font-size: 22px"
                ><span class="font-weight-bold">{{ FDR.name }}</span></span
              >
            </p>
          </div>
        </div>
        <form class="row p-0 m-0 mb-3">
          <div class="d-md-flex col-12 p-0 w-100" style="gap: 5px">
            <div class="col-md-4 col-12 shadow">
              <div class="col-12 p-0 d-flex flex-wrap justify-content-between">
                <p class="h4 col-sm p-0 text-secondary my-auto mr-auto">
                  Informations générales
                </p>
              </div>
              <div class="col-12 p-0">
                <hr class="bg-secondary mb-3 mt-1" />
              </div>

              <div class="col-12 p-0">
                <p>
                  <span>Nom : </span>
                  <span style="color: black" class="font-weight-bold">
                    {{ FDR.name }}
                  </span>
                </p>
                <p>
                  <span>Entité : </span>
                  <span style="color: black" class="font-weight-bold">{{
                    FDR.organisation ? FDR.organisation.entite.libelle : "-"
                  }}</span>
                </p>
                <p>
                  <span>Organisation : </span>
                  <span style="color: black" class="font-weight-bold">
                    {{
                      FDR.organisation ? FDR.organisation.libelle : "-"
                    }}</span
                  >
                </p>
                <p>
                  <span>Patrimoine : </span>
                  <span style="color: black" class="font-weight-bold">
                    {{ FDR.patrimoine ? FDR.patrimoine.name : "-" }}</span
                  >
                </p>
                <p class="">
                  <span>Responsable finance : </span>

                  <span style="color: black" class="font-weight-bold">
                    {{
                      FDR.finance
                        ? FDR.finance.responsable
                          ? FDR.finance.responsable.firstname +
                            " " +
                            FDR.finance.responsable.lastname
                          : "-"
                        : "-"
                    }}
                  </span>
                </p>

                <p class="">
                  <span>Responsable : </span>
                  <!-- <TableResponsable class="ml-1" :responsable="FDR.responsable" /> -->
                  <span style="color: black" class="font-weight-bold">
                    {{
                      FDR.responsable
                        ? FDR.responsable.firstname +
                          " " +
                          FDR.responsable.lastname
                        : "-"
                    }}
                  </span>
                </p>

                <p>
                  <span>Type FdR : </span>
                  <span style="color: black" class="font-weight-bold"
                    >{{ FDR.type }}
                  </span>
                </p>
                <p>
                  <span>Statut : </span>
                  <span style="color: black" class="font-weight-bold"
                    >{{ FDR.status }}
                  </span>
                </p>
              </div>
            </div>

            <div class="col-md-4 mt-2 mt-md-0 col-12 shadow">
              <div class="col-12 p-0 d-flex flex-wrap justify-content-between">
                <p class="h4 text-secondary my-auto mr-auto">Avancement</p>
              </div>
              <div class="col-12 p-0">
                <hr class="bg-secondary mb-3 mt-1" />
              </div>

              <div class="col-12 p-0">
                <p>
                  <span>Date début : </span>
                  <span style="color: black" class="font-weight-bold">
                    {{
                      moment(FDR.startedAt, "DD/MM/YYYY").format("DD/MM/YYYY")
                    }}
                  </span>
                </p>

                <p>
                  <span>Date fin : </span>
                  <span style="color: black" class="font-weight-bold">
                    {{ FDR.endingAt }}
                  </span>
                </p>

                <p class="d-flex p-0 m-0">
                  <span> Avancement : </span>
                  <span style="color: black" class="ml-1 col-8 p-0 my-auto">
                    <b-progress
                      max="100"
                      show-progress
                      height="15px"
                      style="background-color: #cacccd"
                    >
                      <b-progress-bar
                        :value="FDR.avancement || 0"
                        :label="`${FDR.avancement || 0}%`"
                        :style="`background-color: ${
                          FDR.avancement > 20 && FDR.avancement <= 70
                            ? '#f5f245'
                            : FDR.avancement > 70
                            ? '#23d92c'
                            : '#f5ba45'
                        }`"
                      ></b-progress-bar> </b-progress
                  ></span>
                </p>
              </div>
            </div>
            <div class="col-md-4 mt-2 mt-md-0 col-12 shadow">
              <div class="col-12 p-0 d-flex flex-wrap justify-content-between">
                <p class="h4 text-secondary my-auto mr-auto">Couvertures</p>
              </div>
              <div class="col-12 p-0">
                <hr class="bg-secondary mb-3 mt-1" />
              </div>
              <div class="col-12 row">
                <div class="row m-0 p-0">
                  <div
                    style="height: 50px; position: relative; overflow: visible"
                    class="shadow"
                    v-for="(covert, index) in FDR.couvertures"
                    :key="index"
                  >
                    <p>
                      <country-flag
                        :country="covert.countryCode"
                        size="small"
                      />{{ covert.country || "" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="row my-4">
          <div class="col-12">
            <div class="shadow">
              <div class="row">
                <div class="col-12">
                  <h4 class="mb-3">Finance</h4>
                </div>
              </div>
              <fdr-finance />
              <RevisionHistorique
                :budget="FDR.budget"
                :finance="initiativeFinance"
              />
            </div>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-12">
            <div class="shadow">
              <div class="row">
                <div class="col-12">
                  <h4 class="mb-3">Enjeux stratégique</h4>
                </div>
              </div>

              <StrategicChallenge :objectifs="FDR.objectifs" />
            </div>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-12">
            <div class="shadow">
              <div class="row">
                <div class="col-12">
                  <h4 class="mb-3">Initiative</h4>
                </div>
              </div>
              <Initiatives
                :initiatives="initiatives"
                :responsables="responsables"
                :fdrName="newFdr.name"
              />
            </div>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-12">
            <div class="shadow">
              <div class="row">
                <div class="col-12">
                  <h4 class="mb-3">Plan de transformation</h4>
                </div>
              </div>
              <transformation-plan />
            </div>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-12">
            <div class="shadow">
              <div class="row">
                <div class="col-12">
                  <h4 class="mb-3">Comités et décision</h4>
                </div>
              </div>
              <committee-decision />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CogApi from "@/assets/images/application/cog-api.png";

import html2pdf from "html2pdf.js";
import FdrFinance from "./components/FdrFinance.vue";
import StrategicChallenge from "./components/StrategicChallenge.vue";
import Initiatives from "./components/Initiatives.vue";
import CommitteeDecision from "./components/CommitteeDecision.vue";
import TransformationPlan from "./components/TransformationPlan.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import RevisionHistorique from "./components/RevisionHistorique.vue";
import CountryFlag from "vue-country-flag";

export default {
  components: {
    FdrFinance,
    StrategicChallenge,
    Initiatives,
    CommitteeDecision,
    TransformationPlan,
    RevisionHistorique,
    CountryFlag,
  },
  props: {},
  data: () => ({
    printing: false,

    newFdr: {
      endingAt: "",
      startedAt: "",
      status: "",
      type: "",
      responsable: "",
      sponsor: "",
      patrimoine: "",
      organisation: "",
      entite: "",
      name: "",
    },
    cogApi: CogApi,
    gdpr: {
      tableFields: [
        { key: "traitment", label: "Traitement", sortable: true },
        { key: "status", label: "Etat", sortable: true },
      ],
      tableItems: [
        {
          traitment: "Anonymisation",
          status: "En cours",
        },
        {
          traitment: "Restriction",
          status: "En prod",
        },
        {
          traitment: "Purge",
          status: "Terminé",
        },
      ],
    },
    fonction: {
      tableItems: [
        { label: "Rerum aspernatur" },
        { label: "Dolore" },
        { label: "Quibusdam" },
      ],
      tableFields: [{ key: "label", label: "Libellé", sortable: true }],
    },
    data: {
      tableItems: [
        { business_object: "Clients", dcp: 1, sensitive: 3 },
        { business_object: "Dossier", dcp: 0, sensitive: 1 },
        { business_object: "Equipement", dcp: 0, sensitive: 0 },
      ],
      tableFields: [
        { key: "business_object", label: "Objets métier", sortable: true },
        { key: "dcp", label: "DCP", sortable: true },
        { key: "sensitive", label: "Sensible", sortable: true },
      ],
    },
    transformation: {
      tableFields: [
        { key: "fdr", label: "Feuilles de route", sortable: true },
        { key: "initiatives", label: "Initiative", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "patrimoine", label: "Patrimoine", sortable: true },
        { key: "impact_type", label: "Type impact", sortable: true },
        {
          key: "impact_description",
          label: "Description impact",
          sortable: true,
        },
        { key: "end_date", label: "Date de fin", sortable: true },
      ],
      tableItems: [
        {
          fdr: "Fdr 1",
          initiatives: "Initiative 1",
          po: "Velit",
          patrimoine: "Soluta",
          impact_type: "Création",
          impact_description: "Omnis non sit eos quisquam.",
          end_date: "12-12-2021",
        },
        {
          fdr: "Fdr 2",
          initiatives: "Initiative 1",
          po: "Velit",
          patrimoine: "Soluta",
          impact_type: "Suppression",
          impact_description:
            "Nesciunt doloremque explicabo id nam corporis maiores quis et rerum.",
          end_date: "12-12-2021",
        },
      ],
    },
  }),
  methods: {
    exportToPDF() {
      this.printing = true;

      html2pdf()
        .set({
          margin: 1,
          filename: `Fdr-${this.FDR.id}.pdf`,
          image: { type: "jpg", quality: 0.95 },
          html2canvas: { dpi: 192 },
          jsPDF: { unit: "in", format: "a1", orientation: "portrait" },
          pagebreak: { mode: "avoid-all", after: ".page" },
        })
        .from(this.$refs.document)
        .save()
        .then(() => {
          this.printing = false;
        });
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchFdrStatus");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchFdrTypes");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("entity/fetchAllEntities");
    this.$store.dispatch("fdr/fetchFdr", this.$route.params.id);
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["FDR_STATUS"]), //get all status
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("type", ["FDR_TYPES"]),
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("entity", ["ENTITIES"]),
    ...mapGetters("fdr", ["FDR"]),
    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusFdr() {
      return this.FDR_STATUS;
    },
    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: `/api/referentiel/organisations/${organisation.id}`,
        text: organisation.libelle,
      }));
    },
    fdrTypes() {
      return this.FDR_TYPES;
    },
    patrimoineList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        value: patrimoine["@id"],
        text: patrimoine.name,
      }));
    },
    entityList() {
      return this.ENTITIES.map((entite) => ({
        value: `/api/referentiel/entites/${entite.id}`,
        text: entite.libelle,
      }));
    },
    objectifsList() {
      return this.FDR.objectifs;
    },
    initiatives() {
      return this.FDR.initiatives.map((init) => ({
        ...init,
        actions: ["show", "edit", "transfer", "delete"],
      }));
    },
    ecartBudget() {
      var ecrants = 0;
      this.FDR.finance
        ? this.FDR.finance.ajustements.map((ajustement) => {
            ecrants += parseInt(ajustement.montant);
          })
        : "";
      return ecrants;
    },
    lastAdjustement() {
      return this.FDR.finance && this.FDR.finance.ajustements.length
        ? moment(this.FDR.finance.ajustements[0].createdAt).format("DD/MM/YYYY")
        : "-";
    },
    initiativeFinance() {
      return this.newFdr.finance;
    },
  },
  watch: {
    FDR() {
      this.newFdr = {
        ...this.FDR,

        entite:
          this.FDR.organisation && this.FDR.organisation.entite
            ? this.FDR.organisation.entite["@id"]
            : null,
        organisation: this.FDR.organisation["@id"],
        patrimoine: this.FDR.patrimoine["@id"],
        responsable: this.FDR.responsable["@id"],
        startedAt: datePickerFormat(this.FDR.startedAt),
        endingAt: datePickerFormat(this.FDR.endingAt),
      };
      window.console.table(this.FDR);
    },
  },
};
</script>

<style>
.shadow {
  padding: 20px;
}
</style>
