<template>
  <div>
    <div
      v-if="!$route.path.includes('print')"
      class="d-flex flex-wrap justify-content-between align-items-center"
    >
      <div>
        <span class="font-weight-bold mr-1"> Initiative : </span>
        <DropdownSelection
          class="w-25"
          :text="selectedTransformationInitiative.text"
          :items="transformationInitiativeOptions"
          @selected="selectInitiative"
        ></DropdownSelection>
      </div>
      <div>
        <span class="font-weight-bold mr-1"> Type objet : </span>
        <DropdownSelection
          class="w-25"
          :text="selectedTransformationApplication.text"
          :items="transformationApplicationOptions"
          @selected="(item) => (selectedTransformationApplication = item)"
        ></DropdownSelection>
      </div>

      <div
        v-if="
          TRANSFORMATION_PLANS &&
          TRANSFORMATION_PLANS.initiatives &&
          transformationPlanItems.length
        "
        @click.prevent="exportToPDF"
        class="mdl_btn bg-primary"
        style="font-size: 20px; cursor: pointer"
      >
        <i style="color: white" class="mdi mdi-printer"></i>
      </div>
      <div v-else></div>
    </div>
    <div
      v-if="
        TRANSFORMATION_PLANS &&
        TRANSFORMATION_PLANS.initiatives &&
        TRANSFORMATION_PLANS.initiatives.length
      "
      ref="plan"
    >
      <div
        class="w-100 d-flex flex-column border p-2 mt-4 rounded"
        v-if="
          !$route.path.includes('print') &&
          TRANSFORMATION_PLANS &&
          TRANSFORMATION_PLANS.initiatives &&
          TRANSFORMATION_PLANS.initiatives.length &&
          selectedTransformationApplication.value === 'application'
        "
      >
        <!-- foreach transformation in transformation table -->
        <div
          v-for="(transformation, index) in groupBy(
            transformationPlanItems,
            'application.capacite.id'
          )"
          :key="index"
        >
          <p class="text-secondary pb-0">
            {{
              transformation[0].application &&
              transformation[0].application.capacite
                ? transformation[0].application.capacite.name
                : "N/A"
            }}
          </p>
          <div
            class="
              row
              rounded
              bg-light
              shadow-sm
              border
              col-12
              mx-auto
              mb-3
              p-0
            "
          >
            <div
              v-for="(tran, i) in transformation"
              :key="'tran' + i"
              class="col-12 col-md-2"
            >
              <div class="py-2">
                <div>
                  <b-link
                    @click="showModal = !showModal"
                    :to="{
                      name: 'show-application',
                      params: {
                        id: tran.application.id,
                        slug: tran.application.slug || 'abcde',
                      },
                    }"
                    :class="
                      'rounded px-3  py-2 text-truncate  d-inline-block text-white text-decoration-none ' +
                      setBgColor(tran.type)
                    "
                    style="cursor: pointer"
                  >
                    {{ tran.application.name }}
                  </b-link>
                  <div
                    class="text-gray text-center w-100"
                    v-if="!tran.application"
                  >
                    <i class="mdi mdi-information-outline my-auto"></i>
                    Section vide
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!transformation.length"
            class="text-gray d-flex h2 text-center w-100"
            style="height: 166px"
          >
            <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
            <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
          </div>
        </div>
        <div
          v-if="
            !Object.keys(
              groupBy(transformationPlanItems, 'application.capacite.id')
            ).length
          "
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
      </div>

      <div
        class="w-100 d-flex flex-column border p-2 mt-4 rounded"
        v-if="
          !$route.path.includes('print') &&
          TRANSFORMATION_PLANS &&
          TRANSFORMATION_PLANS.initiatives &&
          TRANSFORMATION_PLANS.initiatives.length &&
          selectedTransformationApplication.value === 'composants'
        "
      >
        <div
          v-for="(transformation, index) in groupBy(
            transformationPlanItems,
            'composant.capacites.id'
          )"
          :key="index"
          class="w-100 mb-3"
        >
          <p class="text-secondary pb-0">
            {{
              transformation[0].composant &&
              transformation[0].composant.capacite
                ? transformation[0].composant.capacite.name
                : "N/A"
            }}
          </p>
          <div
            class="
              row
              rounded
              bg-light
              shadow-sm
              border
              col-12
              mx-auto
              mb-3
              p-0
            "
          >
            <div
              v-for="(tran, i) in transformation"
              :key="'tran' + i"
              class="col-12 col-md-2"
            >
              <div class="py-2">
                <div>
                  <b-link
                    @click="showModal = !showModal"
                    :to="{
                      name: 'show-composant',
                      params: {
                        id: tran.composant.id,
                        slug: tran.composant.slug || 'abcde',
                      },
                    }"
                    :class="
                      'rounded px-3  py-2 text-truncate   d-inline-block text-white text-decoration-none ' +
                      setBgColor(tran.type)
                    "
                    style="cursor: pointer"
                  >
                    {{ tran.composant.name }}
                  </b-link>
                  <div
                    class="text-gray text-center w-100"
                    v-if="!tran.composant"
                  >
                    <i class="mdi mdi-information-outline my-auto"></i>
                    Section vide
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            !Object.keys(
              groupBy(transformationPlanItems, 'composant.capacites.id')
            ).length
          "
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
      </div>

      <div
        class="w-100 d-flex flex-column border p-2 mt-4 rounded"
        v-if="
          $route.path.includes('print') &&
          TRANSFORMATION_PLANS &&
          TRANSFORMATION_PLANS.initiatives &&
          TRANSFORMATION_PLANS.initiatives.length
        "
      >
        <!-- foreach transformation in transformation table -->
        <div
          v-for="(transformation, index) in groupBy(
            transformationPlanApplications,
            'application.capacite.id'
          )"
          :key="index"
        >
          <p class="text-secondary pb-0">
            {{
              transformation[0].application &&
              transformation[0].application.capacite
                ? transformation[0].application.capacite.name
                : "N/A"
            }}
          </p>
          <div
            class="
              row
              rounded
              bg-light
              shadow-sm
              border
              col-12
              mx-auto
              mb-3
              p-0
            "
          >
            <div
              v-for="(tran, i) in transformation"
              :key="'tran' + i"
              class="col-12 col-md-2"
            >
              <div class="py-2">
                <div>
                  <span
                    @click="showModal = !showModal"
                    :class="
                      'rounded px-3  py-2 text-truncate   d-inline-block text-white text-decoration-none ' +
                      setBgColor(tran.type)
                    "
                    style="cursor: pointer"
                  >
                    {{ tran.application.name }}
                  </span>
                  <div
                    class="text-gray text-center w-100"
                    v-if="!tran.application"
                  >
                    <i class="mdi mdi-information-outline my-auto"></i>
                    Section vide
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!transformation.length"
            class="text-gray d-flex h2 text-center w-100"
            style="height: 166px"
          >
            <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
            <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
          </div>
        </div>
      </div>

      <!-- /////////////////////////////////-->

      <div
        class="w-100 d-flex flex-column border p-2 mt-4 rounded"
        v-if="
          $route.path.includes('print') &&
          TRANSFORMATION_PLANS &&
          TRANSFORMATION_PLANS.initiatives &&
          TRANSFORMATION_PLANS.initiatives.length
        "
      >
        <div
          v-for="(transformation, index) in groupBy(
            transformationPlanComposants,
            'composant.capacites.id'
          )"
          :key="index"
          class="w-100 mb-3"
        >
          <p class="text-secondary pb-0">
            {{
              transformation[0].composant &&
              transformation[0].composant.capacite
                ? transformation[0].composant.capacite.name
                : "N/A"
            }}
          </p>
          <div
            class="
              row
              rounded
              bg-light
              shadow-sm
              border
              col-12
              mx-auto
              mb-3
              p-0
            "
          >
            <div
              v-for="(tran, i) in transformation"
              :key="'tran' + i"
              class="col-12 col-md-2"
            >
              <div class="py-2">
                <div>
                  <span
                    :class="
                      'rounded px-3  py-2 text-truncate   d-inline-block text-white text-decoration-none ' +
                      setBgColor(tran.type)
                    "
                    style="cursor: pointer"
                  >
                    {{ tran.composant.name }}
                  </span>
                  <div
                    class="text-gray text-center w-100"
                    v-if="!tran.composant"
                  >
                    <i class="mdi mdi-information-outline my-auto"></i>
                    Section vide
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            !Object.keys(
              groupBy(transformationPlanItems, 'composant.capacites.id')
            ).length
          "
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
      </div>

      <div
        class="w-100 d-flex flex-column border p-2 mt-4 rounded"
        v-if="
          $route.path.includes('print') &&
          TRANSFORMATION_PLANS &&
          TRANSFORMATION_PLANS.initiatives &&
          TRANSFORMATION_PLANS.initiatives.length
        "
      >
        <!-- foreach transformation in transformation table -->
        <div
          v-for="(transformation, index) in groupBy(
            transformationPlanApplications,
            'application.capacite.id'
          )"
          :key="index"
        >
          <p class="text-secondary pb-0">
            {{
              transformation[0].application &&
              transformation[0].application.capacite
                ? transformation[0].application.capacite.name
                : "N/A"
            }}
          </p>
          <div
            class="
              row
              rounded
              bg-light
              shadow-sm
              border
              col-12
              mx-auto
              mb-3
              p-0
            "
          >
            <div
              v-for="(tran, i) in transformation"
              :key="'tran' + i"
              class="col-12 col-md-2"
            >
              <div class="py-2">
                <div>
                  <span
                    :class="
                      'rounded px-3  py-2 text-truncate   d-inline-block text-white text-decoration-none ' +
                      setBgColor(tran.type)
                    "
                    style="cursor: pointer"
                  >
                    {{ tran.application.name }}
                  </span>
                  <div
                    class="text-gray text-center w-100"
                    v-if="!tran.application"
                  >
                    <i class="mdi mdi-information-outline my-auto"></i>
                    Section vide
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!transformation.length"
            class="text-gray d-flex h2 text-center w-100"
            style="height: 166px"
          >
            <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
            <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
          </div>
        </div>
        <div
          v-if="
            !Object.keys(
              groupBy(transformationPlanItems, 'application.capacite.id')
            ).length
          "
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
      </div>

      <div
        v-else-if="
          !TRANSFORMATION_PLANS &&
          !TRANSFORMATION_PLANS.initiatives &&
          !TRANSFORMATION_PLANS.initiatives.length
        "
        class="text-gray d-flex h2 text-center w-100"
        style="height: 166px"
      >
        <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
        <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
      </div>
    </div>
    <div
      v-else
      class="text-gray d-flex h2 text-center w-100"
      style="height: 166px"
    >
      <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
      <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
    </div>
  </div>
</template>

<script>
import DropdownSelection from "@/components/custom/DropdownSelection.vue";
import html2pdf from "html2pdf.js";
import { mapGetters } from "vuex";
import { concat, minBy, groupBy } from "lodash";
export default {
  components: {
    DropdownSelection, //Modal
  },
  data: () => ({
    transformationApplicationOptions: [
      { value: "application", text: "Applications" },
      { value: "composants", text: "Composants techniques" },
    ],
    selectedTransformationInitiative: { text: "Toutes", value: null },
    selectedTransformationApplication: {
      text: "Applications",
      value: "application",
    },
    showModal: false,
  }),
  methods: {
    selectInitiative(item) {
      this.selectedTransformationInitiative = item;
    },
    exportToPDF() {
      html2pdf()
        .set({
          margin: 1,
          filename: "document.pdf",
          image: { type: "jpg", quality: 0.95 },
          html2canvas: { dpi: 192 },
          jsPDF: { unit: "in", format: "a1", orientation: "portrait" },
          pagebreak: { mode: "avoid-all", after: ".page" },
        })
        .from(this.$refs.plan)
        .save();
    },
    setBgColor(status) {
      switch (status) {
        case "Suppression":
          return "bg-danger";
        case "Evolution":
          return "bg-warning";
        case "N/A":
          return "bg-gray";
        case "Création":
          return "bg-success";
        default:
          return "bg-secondary";
      }
    },
    getCapacite() {},
    groupBy,
  },
  created() {
    this.$store
      .dispatch("fdr/fetchFdrPlans", this.$route.params.id)
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("status", ["INITIATIVE_STATUS"]), //get all status
    ...mapGetters("phase", ["PHASES"]), //get all phases
    ...mapGetters("fdr", ["FDRS", "INITIATIVES", "TRANSFORMATION_PLANS"]), //get all phases
    phasesList() {
      return this.PHASES.map((phase) => ({
        ...phase,
        value: phase["@id"],
        text: phase.libelle,
      }));
    },
    rows() {
      return this.tableItems.length;
    },
    transformationInitiativeOptions() {
      var options = [];
      if (this.TRANSFORMATION_PLANS && this.TRANSFORMATION_PLANS.initiatives)
        options = this.TRANSFORMATION_PLANS.initiatives.map((initiative) => ({
          text: initiative.libelle,
          value: initiative["@id"],
        }));
      options.unshift({ text: "Toutes", value: null });
      return options;
    },
    transformationPlanItems() {
      var transformations = [];
      if (this.TRANSFORMATION_PLANS && this.TRANSFORMATION_PLANS.initiatives) {
        this.TRANSFORMATION_PLANS.initiatives
          .filter((initiative) =>
            this.selectedTransformationInitiative.value
              ? initiative["@id"] ===
                this.selectedTransformationInitiative.value
              : true
          )
          .map((initiative) => {
            this.selectedTransformationApplication.value === "application"
              ? (transformations = concat(
                  transformations,
                  initiative.impacts.filter((impact) => impact.application)
                ))
              : (transformations = concat(
                  transformations,
                  initiative.impacts.filter((impact) => impact.composant)
                ));
          });
        transformations = transformations.map((trans) => {
          if (
            trans.application &&
            trans.application.capaciteSis &&
            trans.application.capaciteSis.length
          ) {
            let capacite = minBy(trans.application.capaciteSis, "niveau");
            if (capacite && capacite.parent) {
              capacite = capacite.parent;
            }
            return {
              ...trans,
              application: {
                ...trans.application,
                capacite,
              },
            };
          } else if (
            trans.composant &&
            trans.composant.capacites &&
            trans.composant.capacites.length
          ) {
            let capacite = minBy(trans.composant.capacites, "niveau");
            if (capacite && capacite.parent) {
              capacite = capacite.parent;
            }
            return {
              ...trans,
              composant: {
                ...trans.composant,
                capacite,
              },
            };
          } else {
            return trans;
          }
        });
        return transformations;
      } else return [];
    },

    transformationPlanApplications() {
      var transformations = [];
      if (this.TRANSFORMATION_PLANS && this.TRANSFORMATION_PLANS.initiatives) {
        this.TRANSFORMATION_PLANS.initiatives
          .filter((initiative) =>
            this.selectedTransformationInitiative.value
              ? initiative["@id"] ===
                this.selectedTransformationInitiative.value
              : true
          )
          .map((initiative) => {
            transformations = concat(
              transformations,
              initiative.impacts.filter((impact) => impact.application)
            );
          });
        transformations = transformations.map((trans) => {
          if (
            trans.application &&
            trans.application.capaciteSis &&
            trans.application.capaciteSis.length
          ) {
            let capacite = minBy(trans.application.capaciteSis, "niveau");
            if (capacite && capacite.parent) {
              capacite = capacite.parent;
            }
            return {
              ...trans,
              application: {
                ...trans.application,
                capacite,
              },
            };
          } else {
            return trans;
          }
        });
        return transformations;
      } else return [];
    },

    transformationPlanComposants() {
      var transformations = [];
      if (this.TRANSFORMATION_PLANS && this.TRANSFORMATION_PLANS.initiatives) {
        this.TRANSFORMATION_PLANS.initiatives
          .filter((initiative) =>
            this.selectedTransformationInitiative.value
              ? initiative["@id"] ===
                this.selectedTransformationInitiative.value
              : true
          )
          .map((initiative) => {
            transformations = concat(
              transformations,
              initiative.impacts.filter((impact) => impact.composant)
            );
          });
        transformations = transformations.map((trans) => {
          if (
            trans.composant &&
            trans.composant.capacites &&
            trans.composant.capacites.length
          ) {
            let capacite = minBy(trans.composant.capacites, "niveau");
            if (capacite && capacite.parent) {
              capacite = capacite.parent;
            }
            return {
              ...trans,
              composant: {
                ...trans.composant,
                capacite,
              },
            };
          } else {
            return trans;
          }
        });
        return transformations;
      } else return [];
    },
  },
};
</script>

<style>
.bg-gray {
  background-color: gray;
}
</style>
