<template>
  <div>
    <div class="row p-0 m-0 flex-wrap">
      <!-- <div
        class="col-sm-6 pl-sm-0 d-flex flex-column justify-content-between mb-3"
      > -->
      <div class="col-12 p-1">
        <div>
          <div class="hdr py-2 text-white rounded mb-3">
            Situation actuelle : {{ moment().format("DD/MM/YYYY") }}
          </div>
          <div class="px-4 pt-4">
            <p>
              <span>Budget total des initiatives : </span>
              <span class="font-weight-bold"> {{ initiativeBudget }}€</span>
            </p>
            <p>
              <span>Budget FDR : </span>
              <span class="font-weight-bold"> {{ FDR.budget }}€ </span>
            </p>
            <p>
              <span>Ecart budget : </span
              ><span
                class="font-weight-bold"
                style="color: #1dbf4d; font-weight: bold"
              >
                {{ ecartBudget }}€</span
              >
            </p>
          </div>
          <div class="px-4 pb-4">
            <p>
              <span>Dernier ajustement : </span>
              <span
                class="font-weight-bold"
                v-if="FDR.finance && FDR.finance.ajustements.length"
              >
                {{ lastAdjustement }} par
                {{
                  FDR.finance.responsable.firstname +
                  " " +
                  FDR.finance.responsable.lastname
                }}</span
              >
              <span v-else> -</span>
            </p>
          </div>
        </div>
      </div>
      <div v-show="false" class="col-sm-6 pr-sm-0">
        <div class="hdr py-2 text-white rounded mb-3">Suivi conso:</div>
        <PieChart style="width: 100%" :options="pieOptions" />
      </div>
    </div>

    <div class="hdr py-2 text-white rounded mt-4 mb-3">Historique</div>
  </div>
</template>

<script>
import PieChart from "@/components/charts/c3-chart/donut-c3.vue";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    PieChart,
  },
  data: () => ({
    financeHistory: {
      tableItems: [
        {
          date_revision: "10/11/2021",
          resp_fin: {
            name: "S.E",
            full_name: "Steve Ewore",
            img: "https://source.unsplash.com/random/150x150",
          },
          budget_fdr_initial: "10.000€",
          montant_ajuste: "-2.000€",
          budget_fdr_revise: "8.000€",
          tendance: "-20%",
        },
      ],
      tableFields: [
        {
          key: "date_revision",
          label: "Date révision",
          sortable: true,
        },
        { key: "resp_fin", label: "Resp Fin", sortable: true },
        {
          key: "budget_fdr_initial",
          label: "Budget FDR initial",
          sortable: true,
        },
        {
          key: "montant_ajuste",
          label: "Montant ajusté",
          sortable: true,
        },
        {
          key: "budget_fdr_revise",
          label: "Budget FDR révisé",
          sortable: true,
        },
        { key: "tendance", label: "Tendance", sortable: true },
        // { key: "actions", label: "", sortable: false },
      ],
    },
    pieOptions: {
      data: {
        columns: [
          ["Consommé", 6000],
          ["Reste", 4000],
        ],
        type: "donut",
      },
      color: {
        pattern: ["#FFC000", "#072648"],
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 0,
      },
      bar: {
        width: {
          ratio: 0.7, // this makes bar width 50% of length between ticks
        },
      },
    },
  }),
  created() {
    this.$store.dispatch("fdr/fetchFdr", this.$route.params.id);
  },
  computed: {
    ...mapGetters("fdr", ["FDR"]),

    lastAdjustement() {
      return this.FDR.finance && this.FDR.finance.ajustements.length
        ? moment(
            this.FDR.finance.ajustements[
              this.FDR.finance.ajustements.length - 1
            ].createdAt
          ).format("DD/MM/YYYY")
        : "-";
    },
    ecartBudget() {
      var ecrants = 0;
      this.FDR.finance
        ? this.FDR.finance.ajustements.map((ajustement) => {
            ecrants += parseInt(ajustement.montant);
          })
        : "";
      return ecrants;
    },
    initiativeBudget() {
      var initiativeBudget = 0;
      this.FDR.initiatives.map((init) => {
        initiativeBudget += parseFloat(init.budget || 0);
      });
      return initiativeBudget;
    },
  },
};
</script>

<style scoped>
.txt_desc {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.table_responsivo {
  overflow: scroll;
}

.popUpFather {
  position: relative;
}

@media (min-width: 991px) {
  .modal-xyz-548 {
    position: absolute;
    /* background-color: rgba(0, 0, 0, 0.377); */
    width: calc(100% - 331px) !important;
    height: 72% !important;
    top: 165px !important;
    right: 35px !important;
    /* transform: translateY(-50%) !important; */
    z-index: 3000;
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    /* align-items: center; */
    /* -webkit-box-pack: center; */
    -ms-flex-pack: center;
    /* justify-content: center; */
  }
}

@media (max-width: 991px) {
  .modal-xyz-548 {
    width: 100%;
  }
}

.sub-modal {
  background-color: white;
  padding: 20px 30px;
  /* border-radius: 10px; */
  /* min-width: 950px;
  max-width: 950px;
  max-height: 80%; */
  width: 100%;
  height: 100%;
  position: relative;
}

.sb_mdl_ctrls {
  /* position: absolute; */
  /* top: -45px; */
  /* background-color: rebeccapurple; */
  height: 40px;
  /* right: 0px; */
  /* width: 100px; */
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
}

.mdl_btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}

.tr_clckble {
  cursor: pointer;
}

.tr_clckble:hover {
  background-color: rgba(139, 44, 235, 0.342);
}

.hdr {
  background-color: #072648;
  text-align: center;
}

.hdr > p {
  color: white;
}

.spn {
  color: #797979;
}
</style>
